<template>
  <div class="card mb-0" :class="[cssClass.bg, cssClass.text]" @click="toSetting">
    <div class="card-body p-1">
      <div class="d-flex">
        <div class="pt-1" style="width: 25%;">
          <img :src="marketIcon" class="market-icon" style="width: 100%">
        </div>
        <div class="pl-2" style="width: 100%;">
          <h4 class="m-0">{{marketTitle}}</h4>
          <strong>{{roundDate}}</strong>
          <div class="d-flex justify-content-between small">
            <div>เปิดรับ</div>
            <div class="text-right">{{openDate}}</div>
          </div>
          <div class="d-flex justify-content-between small">
            <div>ปิดรับ</div>
            <div class="text-right">{{closeDate}}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>สถานะ</div>
            <div class="text-right">
              <RoundStatus :data="data" @cssStyle="setStyle" />
            </div>
          </div>
          <!-- <div class="text-right mt-2">
            <button class="btn btn-sm btn-link pr-0" :class="cssClass.btn" @click="toSetting"><i class="fas fa-cog"></i> ตั้งค่า</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import RoundStatus from './RoundStatus'

export default {
  name: 'LottoRound',
  props: ['data'],
  components: {
    RoundStatus
  },
  data() {
    return {
      cssClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      }
    }
  },
  computed: {
    roundDate() {
      return moment(this.data.roundDate.date).format("DD-MM-YYYY")
    },
    openDate() {
      return moment(this.data.roundDate.open).format("DD/MM/YY HH:mm:ss")
    },
    closeDate() {
      return moment(this.data.roundDate.close).format("DD/MM/YY HH:mm:ss")
    },
    marketTitle() {
      const market = this.$store.state.markets.find((m)=>{
        return m._id === this.data.marketId
      })

      return market?.marketTitle || this.data.marketId
    },
    marketIcon() {
      const market = this.$store.state.markets.find((m)=>{
        return m._id === this.data.marketId
      })

      return market?.imageIcon || null
    }
  },
  methods: {
    setStyle(css) {
      this.cssClass = {
        ...this.cssClass,
        ...css
      }
    },
    toSetting() {
      console.log(this.data)
      this.$store.commit('updateGlobalMarket', {
        groupId: this.data.groupId,
        marketId: this.data.marketId,
        round: {
          roundId: this.data._id,
          roundDate: this.data.roundDate
        }
      })

      this.$router.push({
        name: 'LottoSettings'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.small {
  font-size: 70%;
}

.market-icon {
  border-radius: 2px;
}

.card {
  cursor: pointer;

  h4 {
    font-size: 110%;
  }
}
</style>
