<template>
  <div id="dashboard" class="container-fluid">
  	<div class="d-flex align-items-center">
  		<div class="d-flex align-items-center">
        <div style="width: 120px;">
          <strong>งวดวันที่</strong>
        </div>
        <flat-pickr
          id="rounds-date"
          :config="{
            enableTime: false,
            dateFormat: 'Y-m-d',
            maxDate: maxDate
          }"
          v-model="date"
          class="form-control form-control-md"
        />
      </div>
      <button class="btn btn-info ml-2" @click="toggleTomorrow">พรุ่งนี้</button>
      <button class="btn btn-info ml-2" @click="toggleToday">วันนี้</button>
      <button class="btn btn-info ml-2" @click="toggleYesterday">เมื่อวาน</button>
  		<!-- <button class="btn btn-sm btn-link" @click="addRound">เพิ่มงวดหวย</button> -->
  	</div>
    <hr class="mt-2 mb-3">
    <div v-for="group in groups" :key="group._id" class="mb-3">
      <h5>{{group.groupTitle}}</h5>
      <div class="row no-gutters">
      	<div v-for="round in group.rounds" :key="round._id" class="col-md-3 px-1 mb-2">
      		<LottoRound :data="round" />
      	</div>
      </div>
    </div>
    <div v-if="!groups.length">
      ไม่มีงวดหวย
    </div>

    <AddRoundModal :is-show="isShowRoundModal" @close="modalClosed" />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import AddRoundModal from './components/AddRoundModal.vue'
import LottoRound from './components/LottoRound.vue'

import moment from '@/services/moment'
import LottoService from '@/services/lottoService'

import _ from 'lodash'

export default {
	components: {
    AddRoundModal,
    LottoRound,
    Loading,
    flatPickr
	},
  data() {
    return {
      date: null,
      today: moment().add(21, 'h').format("YYYY-MM-DD"),
      maxDate: moment().add(30, 'd').format("YYYY-MM-DD"),
      isLoading: false,
      isShowRoundModal: false,
      data: null
    }
  },
  computed: {
    groups() {

      if(!this.data)
        return []

      return _.orderBy(this.data.groups, ['sort'], ['asc'])
      .map((group)=>{
        const rounds = this.data.rounds.filter((round)=>{
          return group._id === round.groupId
        })

        group.rounds = _.orderBy(rounds, (round)=>{
          const diff = moment(round.roundDate.close).diff(moment(), 's')
          if(diff > 0) {
            return diff
          }else{
            return 10000000 + diff
          }
        }, ['asc'])
        return group
      })
      .filter((group)=>{
        return group.rounds.length > 0
      })
    },
    globalRoundDate() {
      return this.$store.state?.globalMarket?.round?.roundDate.date
    }
  },
  watch: {
    date() {
      this.getRounds()
    },
    globalRoundDate() {
      console.log('globalRoundDate!!!')
      if(this.globalRoundDate) {
        this.date = this.globalRoundDate
      }
    }
  },
  methods: {
    getRounds() {
      LottoService.getRoundByDate(this.date)
      .then((response)=>{
        if(response.success) {
          console.log('getRounds', response.data)
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    addRound() {
      this.isShowRoundModal = true
    },
    modalClosed(needReload) {
      this.isShowRoundModal = false
      if(needReload) {
        console.log('reload')
        this.getRounds()
      }
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    groupName(groupId) {
      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group?.groupTitle
      }else{
        return groupId
      }
    },
    toggleTomorrow() {
      this.date = moment().add(1, 'd').format("YYYY-MM-DD")
    },
    toggleToday() {
      this.date = moment().format("YYYY-MM-DD")
    },
    toggleYesterday() {
      this.date = moment().add(-1, 'd').format("YYYY-MM-DD")
    }
  },
  mounted() {
    //this.$store.dispatch('reloadMarketGroups')
    this.date = this.$store.state?.globalMarket?.round?.roundDate.date || moment().add(-3, 'h').format("YYYY-MM-DD")
    this.getRounds()
  }
}
</script>
<style lang="scss" scoped>
.container {
	.alert-secondary {
		background-color: #F5F5F5;
		border: 0;
	}
}
</style>
